export default function InsideGrid({action}) {
  return (
    <>
    <div className='responsive-grid'>

    </div>
    <div className='responsive-grid'>

    </div>
    <div className='responsive-grid'>

    </div>
  </>
  );
}