// import { useContext } from 'react';
// import { UserContext } from '../App';
import InsideHead from '../components/inside/InsideHead';
import InsideContent from '../components/inside/InsideContent';

export default function InsideHouse() {
  // const { language } = useContext(UserContext);
  
  return (
    <>
      <InsideHead />
      <InsideContent />
    </>
  );
}