import FacilitiesIntro from './FacilitiesIntro';

export default function FacilitiesContent() {

  return (
    <div className='space-above'>
      <FacilitiesIntro />
    </div>
  );
}

